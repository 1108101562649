import React, {Component} from 'react';

import {Amplify} from 'aws-amplify';

//Components
import HomePage from './components/HomePage';
import SignInPage from './components/SignInPage';

//Utils
import {authConfig} from './auth/auth-config';
import {isUserLoggedIn} from './auth/auth';

Amplify.configure(authConfig);

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {userIsLoggedIn: false};

		this.setUserIsLoggedIn = this.setUserIsLoggedIn.bind(this);
	}

	componentDidMount() {
		isUserLoggedIn().then(currentLoginStatus => this.setUserIsLoggedIn(currentLoginStatus));
	}

	setUserIsLoggedIn(currentLoginStatus) {
		this.setState({userIsLoggedIn: currentLoginStatus});
	}

	render() {
		if(this.state.userIsLoggedIn)
			return (<HomePage/>);
		else {
			return (<SignInPage callback={this.setUserIsLoggedIn}/>);
	}
	}
}

export default App;