import {Field} from 'formik';

export const MonthPicker = () => {
	return (
		<Field as="select" name="month" className="w-33">
			{options(12)}
		</Field>
	);
};

export const DayPicker = () => {
	return (
		<Field as="select" name="day" className="w-33">
			{options(31)}
		</Field>
	);
};

export const YearPicker = () => {
	return (
		<Field as="select" name="year" className="w-33">
			<option value="2021" key="2021">2021</option>
			<option value="2022" key="2022">2022</option>
			<option value="2023" key="2023">2023</option>
			<option value="2024" key="2024">2024</option>
			<option value="2025" key="2025">2025</option>
		</Field>
	);
};

const options = numPickers => {
	return (
		[...Array(numPickers)].map((x, i) =>
			<option value={i+1} key={i+1}>{i+1}</option>
		)
	);
};