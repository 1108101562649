import { Auth } from 'aws-amplify';
import axios from 'axios';

const domain = 'https://oqg9ync8ci.execute-api.us-east-1.amazonaws.com';
const contextRoot = '/v1/expenses';

export const expenseService = (values, setSubmitting, setResponseMessage, setErrorMessage) => {
	setResponseMessage(null);
	setErrorMessage(null);

	postExpense(values).then(response => {
		getTotalExpenses(values.month, values.year).then(response => {
			setResponseMessage(`Remaning total for month: $${response.data}`);
		}).catch(error => {
			setErrorMessage('Posted expense but got error getting total: ', error);
		});
	}).catch(
		error => {
			if(error?.response?.status === 400){
				values.type='debit';
				setErrorMessage('Server side validation error.');
			} else if (error?.response?.status === 500) {
				setErrorMessage('Internal server error.');
			} else {
				setErrorMessage(`Unknown error ${error} with status code ${error?.response?.status}.`)
			}
		}
	).finally(() => {
		values.type='debit';
		values.amount='';
		values.description='';
		values.category='Recurring';

		setSubmitting(false);
	});
}

const postExpense = requestBody => {
	if (requestBody.type === 'credit')
		delete requestBody.category;
	
	const newDay = requestBody.day;
	const newMonth = requestBody.month;
	const newYear = requestBody.year;

	requestBody.day = newDay.toString();
	requestBody.month = newMonth.toString();
	requestBody.year = newYear.toString();

    // const serviceUrl = (process.env.REACT_APP_PERSONAL_WEBSITE_ENV?.toLowerCase() === 'dev') ?
	// 	'http://localhost:3000/v1/expenses' :
	// 	'https://karlmenzel.dev/v1/expenses';

	const endpoint = (requestBody.type==='credit') ? '/credits' : (requestBody.type==='debit') ? '/debits' : null;

	delete requestBody.type;

	return postRequest(endpoint, requestBody);
}

const getTotalExpenses = (month, year) => {
	const serviceUrl = `?year=${year}&month=${month}`;

	return getRequest(serviceUrl);
}

export const getItemizedExpenses = (month, year, setIsGettingDebitItems, setIsGettingCreditItems, setDebitItems, setCreditItems) => {
	setIsGettingDebitItems(true);
	setIsGettingCreditItems(true);

	getDebits(month, year).then(response => {
		setDebitItems(response.data?.sort(compareItems).reverse());
		setIsGettingDebitItems(false);
	});

	getCredits(month, year).then(response => {
		setCreditItems(response.data?.sort(compareItems).reverse());
		setIsGettingCreditItems(false);
	});
};

export const getCategories = (year, month, setIsGettingCategories, setCategories) => {
	setIsGettingCategories(true);

	getCategoriesService(year, month).then(response => {
		setCategories(response.data);
		setIsGettingCategories(false);
	})
};

export const getSummary = (year, month, setIsGettingSummary, setSummary) => {
	setIsGettingSummary(true);

	getSummaryService(year, month).then(response => {
		setSummary(response.data);
		setIsGettingSummary(false);
	})
};

const compareItems = (expenseOne, expenseTwo) => {
	const dayOne = parseInt(expenseOne.day)
	const dayTwo = parseInt(expenseTwo.day)

	if(dayOne < dayTwo)
		return -1;
	if(dayOne > dayTwo)
		return 1;
	return 0;
};

const getDebits = (month, year) => {
	const serviceUrl = `/debits?year=${year}&month=${month}`;

	return getRequest(serviceUrl);
}

const getCredits = (month, year) => {
	const serviceUrl = `/credits?year=${year}&month=${month}`;

	return getRequest(serviceUrl);
}

const getCategoriesService = (year, month) => {
	const serviceUrl = `/categories?year=${year}&month=${month}`;

	return getRequest(serviceUrl);
}

const getSummaryService = (year, month) => {
	const serviceUrl = `/summary?year=${year}&month=${month}`;

	return getRequest(serviceUrl);
}

const postRequest = (endpoint, body) => {
	const url = domain + contextRoot + endpoint;

	return getAuthToken().then(token => {
		const config = {headers: {'Authorization': `Bearer ${token}`}};

		return axios.post(url, body, config);
	});
}

const getRequest = endpoint => {
	const url = domain + contextRoot + endpoint;

	return getAuthToken().then(token => {
		const config = {headers: {'Authorization': `Bearer ${token}`}};

		return axios.get(url, config);
	});
}

const getAuthToken = async () => {
	const currentSession = await Auth.currentSession();

	return currentSession?.idToken?.jwtToken;
}