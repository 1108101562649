import {Field, useField} from 'formik';

export const AmountAndDescriptionInputs = props => {
	const {errors, touched} = props;

	const [field] = useField("amount");
	return (
		<>
			<label className="mx-1 my-1">
				Amount
				<div className="input-group mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<input type="text" {...field} className="form-control" placeholder="0.00" />
				</div>
				{
					touched.amount && errors.amount &&
					<label className="text-danger">{errors.amount}</label>
				}
			</label>

			<br/>

			<label className="mx-1 my-1">
				Description
				<Field name="description" className="form-control"/>
				{
					touched.description && errors.description &&
					<label className="text-danger">{errors.description}</label>
				}
			</label>
		</>
	);
};