import {useField} from 'formik';

export const CategoryInputs = props => {
	const [, meta, helpers] = useField(props.name);

	const {value} = meta;
	const {setValue} = helpers;

	const baseClass = 'w-16 align-top btn ';
	const selectClassName = buttonValue => {return (buttonValue === value) ? 'btn-primary' : 'btn-secondary'};

	return (
		<label className="form-text mx-1 my-1">
			Type
			<br/>
			<div className="button-group">
				<button
					type="button"
					className={baseClass + selectClassName('Grocery')}
					disabled={'Grocery'===value}
					onClick={() => {setValue('Grocery')}}
				>
					Grocery
				</button>

				<button
					type="button"
					className={baseClass + selectClassName('R&E')}
					disabled={'R&E'===value}
					onClick={() => {setValue('R&E')}}
				>
					R&amp;E
				</button>

				<button
					type="button"
					className={baseClass + selectClassName('Travel')}
					disabled={'Travel'===value}
					onClick={() => {setValue('Travel')}}
				>
					Travel
				</button>

				<button
					type="button"
					className={baseClass + selectClassName('Gas')}
					disabled={'Gas'===value}
					onClick={() => {setValue('Gas')}}
				>
					Gas
				</button>

				<button
					type="button"
					className={baseClass + selectClassName('CPG')}
					disabled={'CPG'===value}
					onClick={() => {setValue('CPG')}}
				>
					CPG
				</button>

				<button
					type="button"
					className={baseClass + selectClassName('Recurring')}
					disabled={'Recurring'===value}
					onClick={() => {setValue('Recurring')}}
				>
					Recurring
				</button>
			</div>
		</label>
	);
};