import React, {useState} from 'react';

// Form Components
import {Formik} from 'formik';

// Custom Components
import {CategoryInputs} from './inputs/CategoryInputs';
import {AmountAndDescriptionInputs} from './inputs/AmountAndDescriptionInputs';
import {TransactionTypeInputs} from './inputs/TransactionTypeInputs';
import {MonthPicker, DayPicker, YearPicker} from './inputs/DateInputs';
import {ExpenseItems} from './ExpenseItems';

//Services
import {expenseService} from '../utils/services';

const today = new Date();

export const ExpenseForm = () => {
	const [responseMessage, setResponseMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	return (
		<Formik
			initialValues={{
				type: 'debit',
				amount: '',
				description: '',
				category: 'Recurring',
				month: (today.getMonth()+1),
				day: (today.getDate()),
				year: today.getFullYear()
			}}
			validate={values => {
			    const errors = {};
		        if (!values.amount)
		            errors.amount = 'Required';
			    if (!values.description)
			        errors.description = 'Required';

			    return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				expenseService(values, setSubmitting, setResponseMessage, setErrorMessage);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				isSubmitting,
				setFieldValue
			}) => (
				<form onSubmit={handleSubmit}>
					<TransactionTypeInputs name='type'/>

					<br/>

					<AmountAndDescriptionInputs errors={errors} touched={touched} />

					<br/>

					{(values.type === 'debit') &&
						<>
							<CategoryInputs name={'category'}/>
							<br/>
						</>
					}

					<label className="form-text mx-1 my-1">
						Date
						<br/>
						<MonthPicker values={values} setFieldValue={setFieldValue} />
						<DayPicker values={values} setFieldValue={setFieldValue} />
						<YearPicker values={values} setFieldValue={setFieldValue} />
					</label>

					<br/>

					<button
						type="submit"
						className={"mx-1 mt-3 w-100 btn " + ((isSubmitting) ? "btn-secondary" : "btn-success")}
						disabled={isSubmitting}
					>
						Submit
					</button>

					<br/>

					<div className="mx-1 text-center">
						{(responseMessage) && <label className="text-success">{responseMessage}</label>}
						{(errorMessage) && <label className="text-danger">{errorMessage}</label>}
					</div>

					<br/>

					<ExpenseItems month={values.month} year={values.year}/>
				</form>
			)}
		</Formik>
	);
}