import React, {useState} from 'react';
import { Formik, Field  } from 'formik';

import {login} from '../auth/auth';

const SignInPage = props => {
	const [errorMessage, setErrorMessage] = useState(null);

    return (
        <Formik
            initialValues={{
                username: '',
                password: ''
            }}
            onSubmit={(values, { setSubmitting }) => {
				login(values, setSubmitting, setErrorMessage, props.callback);
			}}
        >
            {({
				values,
				errors,
				touched,
				handleSubmit,
				isSubmitting,
				setFieldValue
			}) => (
				<form onSubmit={handleSubmit}>
					<Field id='username' name='username' placeholder='Username'/>
                    <Field id='password' name='password' placeholder='Password' type='password'/>
                    
                    <button
						type="submit"
						className={"mx-1 mt-3 w-100 btn " + ((isSubmitting) ? "btn-secondary" : "btn-success")}
						disabled={isSubmitting}
					>
						Login
					</button>

                    <div className="mx-1 text-center">
						{(errorMessage) && <label className="text-danger">{errorMessage}</label>}
					</div>
				</form>
			)}
        </Formik>
    );
}

export default SignInPage;