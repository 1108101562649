import React, {useState} from 'react';

// Services
import { getItemizedExpenses, getCategories, getSummary } from '../utils/services';

const adjustDecimal = x => {
    return Number.parseFloat(x).toFixed(2);
};

export const ExpenseItems = props => {
    const [isGettingDebitItems, setIsGettingDebitItems] = useState(false);
	const [isGettingCreditItems, setIsGettingCreditItems] = useState(false);
	const [debitItems, setDebitItems] = useState(null);
	const [creditItems, setCreditItems] = useState(null);
    const [isGettingCategories, setIsGettingCategories] = useState(false);
    const [categories, setCategories] = useState(null);
    const [isGettingSummary, setIsGettingSummary] = useState(false);
    const [summary, setSummary] = useState(null);


	const getDetails = () => {
		getItemizedExpenses(props.month, props.year, setIsGettingDebitItems, setIsGettingCreditItems, setDebitItems, setCreditItems);
	};

    const callGetCategories = () => {
        getCategories(props.year, props.month, setIsGettingCategories, setCategories);
    }

    const callGetSummary = () => {
        getSummary(props.year, props.month, setIsGettingSummary, setSummary);
    }

    return (
        <>
            <div className="button-group">
                <button
                    type="button"
                    className={"w-33 btn " + ((isGettingDebitItems && isGettingCreditItems) ? "btn-secondary" : "btn-primary")}
                    disabled={(isGettingDebitItems && isGettingCreditItems)}
                    onClick={getDetails}
                >
                    Get Items
                </button>

                <button type="button"
                    className={"w-33 btn " + ((isGettingCategories) ? "btn-secondary" : "btn-primary")}
                    disabled={isGettingCategories}
                    onClick={callGetCategories}
                >
                    Get Categories
                </button>

                <button type="button"
                    className={"w-33 btn " + ((isGettingSummary) ? "btn-secondary" : "btn-primary")}
                    disabled={isGettingSummary}
                    onClick={callGetSummary}
                >
                    Get Summary
                </button>
            </div>

            {
                (summary !== null) &&
                    <>
                        <br/>
                        Total Credits: {adjustDecimal(summary.totalCredits)}                        
                        <br/>
                        Total Debits: {adjustDecimal(summary.totalDebits)}
                        <br/>
                        Grocery: {adjustDecimal(summary.categories.grocery)}
                        <br/>
                        R&amp;E: {adjustDecimal(summary.categories.rne)}
                        <br/>
                        Travel: {adjustDecimal(summary.categories.travel)}
                        <br/>
                        Gas: {adjustDecimal(summary.categories.gas)}
                        <br/>
                        CPG: {adjustDecimal(summary.categories.cpg)}
                        <br/>
                        Recurring: {adjustDecimal(summary.categories.recurring)}
                        <br/>
                    </>
            }

            {
                (categories !== null) && 
                    <>
                        <br/>
                        Grocery: {adjustDecimal(categories.grocery)}
                        <br/>
                        R&amp;E: {adjustDecimal(categories.rne)}
                        <br/>
                        Travel: {adjustDecimal(categories.travel)}
                        <br/>
                        Gas: {adjustDecimal(categories.gas)}
                        <br/>
                        CPG: {adjustDecimal(categories.cpg)}
                        <br/>
                        Recurring: {adjustDecimal(categories.recurring)}
                        <br/>
                    </>
            }

            {
                (debitItems !== null) &&
                    <>
                        <br/>
                        <br/>
                        <div className="h4 text-center">Debits</div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Date</th>
                                </tr>
                                {
                                    debitItems.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.description}</td>
                                                <td>${adjustDecimal(item.amount)}</td>
                                                <td>{item.category}</td>
                                                <td>{item.month}/{item.day}/{item.year}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
            }

            <br/>

            {
                (creditItems !== null) &&
                    <>
                        <div className="h4 text-center">Credits</div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Date</th>
                                </tr>
                                {
                                    creditItems.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.description}</td>
                                                <td>${adjustDecimal(item.amount)}</td>
                                                <td>{item.month}/{item.day}/{item.year}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
            }
        </>
    );
};