import {Auth} from 'aws-amplify';

export const isUserLoggedIn = async () => {
    try {
        const currentSession = await Auth.currentSession();

        if(
            currentSession === null || 
            currentSession.idToken === null || 
            currentSession.idToken.jwtToken === null || 
            currentSession.idToken.payload === null || 
            currentSession.idToken.payload.iad === null ||
            currentSession.idToken.payload.exp === null
        )
            return false;

        //The AWS auth time is in seconds so we convert Date.now() from milliseconds to seconds
        const currentTime = Math.floor(Date.now() / 1000);

        return (currentTime >= currentSession?.idToken?.payload?.iat) && (currentTime < currentSession?.idToken?.payload?.exp);
    } catch (error) {
        console.log('User is not logged in');

        Auth.signOut();

        return false;
    }
}

export const login = (values, setSubmitting, setErrorMessage, callback) => {
    setSubmitting(true);

    Auth.signIn(values.username, values.password)
        .then(() => {
            setErrorMessage(null);
            setSubmitting(false);
            isUserLoggedIn().then(userLoginStatus => callback(userLoginStatus));
        }).catch(error => {
            setErrorMessage('User not found');
            setSubmitting(false);
        });
};