import {useField} from 'formik';

export const TransactionTypeInputs = props => {
	const [, meta, helpers] = useField(props.name);

	const {value} = meta;
	const {setValue} = helpers;

	const baseClass = 'w-50 mt-3 align-top btn ';
	const selectClassName = buttonValue => {return (buttonValue === value) ? 'btn-primary' : 'btn-secondary'};

	return (
		<div className="button-group mx-1 my-1">
			<button
				type="button"
				className={baseClass + selectClassName('debit')}
				disabled={'debit'===value}
				onClick={() => {setValue('debit')}}
			>
				Debit
			</button>

			<button
				type="button"
				className={baseClass + selectClassName('credit')}
				disabled={'credit'===value}
				onClick={() => {setValue('credit')}}
			>
				Credit
			</button>
		</div>
	);
};